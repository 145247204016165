const howWorksData = [
    {
        _id: 1,
        sequent: 1,
        description: 'Choose Forwarrd.com as your waste management partner and create an account on Forwarrd.com'
    },
    {
        _id: 2,
        sequent: 2,
        description: 'Save your plastic and organic waste in different dustbin then give your saved waste to our collector and take a recipe from him.'
    },
    {
        _id: 3,
        sequent: 3,
        description: 'Login to your account at Forwarrd.com then send withdraw request and take your award within 24 hours. Enjoy your award and contribute to the protection of the environment.'
    }
]

export default howWorksData;