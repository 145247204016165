export const SET_USER = 'SET_USER';
export const USERS_ERROR = 'USERS_ERROR';
export const LOAD_ALL_USER = 'LOAD_ALL_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const REMOVE_USER = 'REMOVE_USER';
export const LOAD_ALL_COLLECTORS = 'LOAD_ALL_COLLECTORS';
export const CREATE_COLLECTOR = 'CREATE_COLLECTOR';
export const REMOVE_COLLECTOR = 'REMOVE_COLLECTOR';
export const UPDATE_COLLECTOR = 'UPDATE_COLLECTOR';
export const COLLECTOR_ERROR = 'COLLECTOR_ERROR';
export const CREATE_ADMIN = 'CREATE_ADMIN';
export const LOAD_ALL_ADMINS = 'LOAD_ALL_ADMINS';
export const REMOVE_ADMIN = 'REMOVE_ADMIN';
export const UPDATE_ADMIN = 'UPDATE_ADMIN';
export const ADMIN_ERROR = 'ADMIN_ERROR';
export const WITHDRAW_ERROR = 'WITHDRAW_ERROR';
export const DEPOSIT_ERROR = 'DEPOSIT_ERROR';
export const CREATE_WITHDRAW = 'CREATE_WITHDRAW';
export const LOAD_WITHDRAW = 'LOAD_WITHDRAW';
export const LOAD_ALL_WITHDRAW = 'LOAD_ALL_WITHDRAW';
export const UPDATE_WITHDRAW = 'UPDATE_WITHDRAW';
export const CREATE_DEPOSIT = 'CREATE_DEPOSIT';
export const LOAD_DEPOSIT = 'LOAD_DEPOSIT';
export const LOAD_ALL_DEPOSIT = 'LOAD_ALL_DEPOSIT';
export const UPDATE_DEPOSIT = 'UPDATE_DEPOSIT';
export const USER_DEPOSIT = 'USER_DEPOSIT';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';