import React, { Component } from 'react';
import DepositForm from './DepositForm';

class CollectorContent extends Component {
    render() {
        return (
            <>
                <DepositForm></DepositForm>
            </>
        );
    }
}

export default CollectorContent;